

.block-section {

	padding:10px 0;
	margin:20px 0;

	&.large-padding {
		&:before, &:after {display: none;}
		padding:50px 0;
		margin:0;
	}

}

@include media-breakpoint-up(sm) {

}
@include media-breakpoint-up(md) {

.block-section {

	padding:30px 0;
	margin:20px 0;


	&.large-padding {
		padding:60px 0;
	}

}		

}
@include media-breakpoint-up(lg) {

	.block-section {

		&.large-padding {
			padding:6vw 0;
		}

	}	

	.single-post, .error404, .blue-header {
		.main {
			padding-top: $header-desktop-height;
		}
	}

}
@include media-breakpoint-up(xl) {

	.block-section {

		&.large-padding {
			padding:5vw 0;
		}

	}		



}
