.block.columned-content {
	padding: 6vw 0;

	.btn {
		margin-top: 10px;
		margin-bottom:40px;
	}

	.content {
		padding:0;
		p {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		padding: 2vw 0;

		.btn {
			margin-bottom:0;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
