.universal-nav {
	display: none;

	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {
	}
	@include media-breakpoint-up(lg) {
		display: block;
		background-color: $off-black;
		height: 60px;
		position: relative;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.48);

		.icon-utility-call,
		.nav {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: #fff;
			text-decoration: none;
		}

		.icon-utility-call {
			left: 15px;
			color: #fff;
			text-decoration: none;
			font-size: 17px;
			@include hFontNormal;

			&:before {
				color: $secondary;
				position: relative;
				top: 1px;
				padding-right: 8px;
			}
		}

		.nav {
			position: absolute;
			right: 15px;
			height: 40px;
		}

		.social-wrap {
			display: inline-block;
			padding-right:18px;

			a {
				font-size: 17px;
				margin-left: 23px;

				&:hover {
					color: $secondary
				}
			}
		}

		.btn.btn-secondary {
			height: 40px;
			box-sizing: border-box;
			line-height: 40px;
			font-size: 17px;
			padding:0 10px;
			min-width: 125px;

			&:hover {
				background-color: $primary + 20;
				border-color:$primary + 20;
				color:#fff;
			}
		}

		.social-wrap,
		.btn {
			vertical-align: middle;
		}
	}
	@include media-breakpoint-up(xl) {
	}
}
