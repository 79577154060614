/**
 * Gravity Wiz // Gravity Forms // Kayak-style Datepicker
 * Style your Gravity Forms Datepickers like Kayak.com
 *
 *  ENSURE THAT YOUR DATEPICKER FIELD IN GF HAS datepicker-clean CLASS
 *  There is a javascript dependency for this to work also.
 *
 */
.datepicker-clean.ui-datepicker {
    background-color: #fff;
    width: 250px !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    padding-left: 20px;
    position: relative;
    border: 1px solid #ddd;
    padding:10px;
    font-family: $pFont;
}

.datepicker-clean.ui-datepicker:before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #ddd;
    top: -15px;
}

.datepicker-clean.ui-datepicker:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    top: -13px;
}

.datepicker-clean .ui-datepicker-group {
    float: left !important;
    padding: 20px 20px 20px 0;
}

.datepicker-clean .ui-datepicker-header {
    background: none !important;
    text-shadow: none !important;
    color: #333 !important;
    border: 0 !important;
    margin-bottom: 6px !important;
}

.datepicker-clean .ui-datepicker-title {
    font-size: 15px !important;
}

.datepicker-clean .ui-datepicker-year {
    font-weight: normal !important;
}

.datepicker-clean.ui-datepicker thead {
    border: 0 !important;
    background: none !important;
}

.datepicker-clean.ui-datepicker th {
    font-size: 12px !important;
    font-weight: bold;
    color: #ccc !important;
}

.datepicker-clean.ui-datepicker tbody tr {
    border: 0 !important;
}

.datepicker-clean.ui-datepicker tbody td {
    background-color: transparent;
    border: 0 !important;
    padding: 1px !important;
}

.datepicker-clean .ui-datepicker-prev {
    position: absolute;
    left: 10px;
    line-height: 30px !important;
    background: none !important;
}

.datepicker-clean .ui-datepicker-prev:after {
    content: '◀';
    color: $primary !important;
}

.datepicker-clean .ui-state-disabled.ui-datepicker-prev:after {
    color: #ccc !important;
}

.datepicker-clean .ui-icon.ui-icon-circle-triangle-w {
    display: none;
}

.datepicker-clean .ui-datepicker-next {
    position: absolute;
    right: 10px;
    line-height: 30px !important;
    background: none !important;
}

.datepicker-clean .ui-icon-circle-triangle-e {
    display: none;
}

.datepicker-clean .ui-datepicker-next:after {
    content: '▶';
    color: $primary !important;
}

.datepicker-clean .ui-state-disabled.ui-datepicker-next:after {
    color: #ccc !important;
}

.datepicker-clean .ui-datepicker-calendar .ui-state-default {
    background: #f5f5f5 !important;
    font-weight: normal !important;
    font-size: 13px !important;
    text-shadow: none !important;
    border: 1px solid #f5f5f5 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.datepicker-clean .ui-datepicker-calendar .ui-datepicker-today a {
    font-weight: bold !important;
}

.datepicker-clean .ui-datepicker-calendar .ui-datepicker-current-day a {
    background-color: $primary !important;
    color: #fff !important;
    border: 1px solid $primary !important;
}

.datepicker-clean .ui-datepicker-calendar .ui-state-hover {
    background-color: #fff !important;
    border: 1px solid $primary !important;
}

.datepicker-clean .ui-datepicker-unselectable.ui-state-disabled {
    background: none !important;
}

.datepicker-clean .ui-datepicker-unselectable.ui-state-disabled span {
    background: none !important;
    font-weight: normal !important;
    color: #eee !important;
    border: 1px solid transparent !important;
}

.datepicker-clean .ui-datepicker-calendar .ui-datepicker-other-month span {
    color: #ccc !important;
}

.datepicker-clean .ui-datepicker-calendar .wrong {
    background-color: #ff0000 !important;
}

.datepicker-clean div.ui-datepicker-group-first td:not(.ui-datepicker-other-month) ~ td.ui-datepicker-other-month {
    visibility: hidden;
}

.datepicker-clean div.ui-datepicker-group-last td {
    visibility: hidden;
}

.datepicker-clean div.ui-datepicker-group-last td.ui-datepicker-other-month ~ td:not(.ui-datepicker-other-month),
.datepicker-clean div.ui-datepicker-group-last td:not(.ui-datepicker-other-month),
.datepicker-clean div.ui-datepicker-group-last td:not(.ui-datepicker-other-month) ~ td.ui-datepicker-other-month {
    visibility: visible;
}