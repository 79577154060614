.gform_wrapper, form._form {
	margin:20px 0;

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), select, textarea {
		font-size: 12px;
		margin-top:3px;
		border-radius: 12px;
		border:1px solid #fff;
		width:100%;
		box-sizing:border-box;
		margin-top:4px;
		&::placeholder {
			font-size: 12px;
			color: rgba(151,151,151,1);
			font-weight: 100;
		}
	}

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
		
		height: 40px;
		color: $pColor;
		background-color: #fff;
		padding:0px 10px!important;
		transition: $transition;
		width:100%;

		&:hover {
			border:1px solid $secondary;
		}
		&:focus{
			outline:none;
			background-color: #fff;
			border:1px solid $secondary;

		}
	}

	.ginput_container, ._form_element {
		margin-top:15px;
	}

	ul.gfield_checkbox, ul.gfield_radio {
		li {
			overflow: visible !important;
			label {
				top:3px;
				position: relative;
				padding-left:5px;
				line-height: 18px;
			}
		}
	}


		select {
			font-size: 12px;
			position: relative;
			height: 40px;
			color: $pColor;
			background-color: #fff;
			padding:0px 10px!important;
			z-index: 2;
			width:100%!important;
			transition: $transition;
			option:selection {
				color: rgba(151,151,151,1);
			}
			// &::-ms-expand {
			// 	display: none;
			// }
			&:hover {
				border:1px solid $secondary;
			}
			&:focus{
				outline:none;
				background-color: #fff;
				border:1px solid $secondary;
			}
		}


	.top_label .gfield_label, ._form-label {
		margin-bottom: 5px;
		font-family: $pFont;
		font-weight: bold;
		font-size: 13px;
	}

	._error-inner {
		margin:3px 0;
		font-size: 12px;
		color:$secondary;
		font-weight: bold;
	}

	._checkbox-radio {
		label {
			font-weight: normal;
			font-size: 14px;
		}
	}

	textarea {
		padding:10px!important;
		transition: $transition;
		background-color: #fff;
		&:focus {
			outline:none;
			background-color: #fff;
			border:1px solid $secondary;
		}
		&:hover, {
			border:1px solid $secondary;
		}
	}

	div.gform_footer, ._button-wrapper  {
		margin-bottom: 0;
		padding-bottom: 0;
		text-align: center;
		margin-top:20px;
	}

	._submit {
		@extend .btn;
	}



	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}