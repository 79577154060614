.block.interior-banner {

	&:before {display: none;}

	padding:8vw 0 12vw;
	margin-bottom: 10px;
	position: relative;
	color:#fff;
	background-color: $primary;

	.content {
		position: relative;
		top:auto;
		left:auto;
		transform:none;
		margin:0 auto;
		max-width: 800px;
		padding:0;

		p {margin-top:15px;}
	}

	h1 {color:#fff; margin-top:0; margin-bottom: 0;}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.content {
			// margin-bottom: 50px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		padding:calc(8vw + 60px) 0 12vw; // top bar height
		
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		// padding:4vw 0 7vw;
	}
}