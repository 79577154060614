.page-nav {
	margin-top: 30px;
	padding:40px 0 0;
	border-top: 1px solid #e0e0e0;
	margin-top: 60px;
	ul {
		@include list();
		li {
			text-align: center;
			a {
				font-size: 16px;
				font-family: $hFont;
				transition:$transition;
				text-decoration: none;
				color: $primary;
				i {
					font-size:10px;
				}
				span {
					margin:0 10px;
				}
				&:hover {
					color: $grey-dark;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}