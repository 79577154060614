.menus.desktop {
	display: none;
	ul.nav {
		list-style-type: none;
		padding: 0;
		text-align: right;
		margin: 0;
		> li {
			$dropRadius: 5px;

			display: inline-block;
			margin-right: 10px;
			position: relative;
			vertical-align: middle;

			&.callout {
				margin-left: 20px;
				a {
					display: inline-block;
					position: relative;
					text-decoration: none;
					text-align: center;
					transition: $transition;
					border: none;
					outline: none;
					cursor: pointer;
					overflow: hidden;
					@include hFontNormal;
					background-color: $secondary;
					color: #fff;
					border-radius: 12px;
					border: 2px solid $secondary;
					font-size: 18px;
					padding: 9px 20px;
					min-width: 80px;
					text-transform: none;

					&:hover {
						background-color: $secondary + 20;
						border-color: $secondary + 20;
						color: #fff;
					}
				}
			}

			&:hover,
			&.active {
				a {
					// color:$third;
				}
			}
			a {
				padding: 10px 20px;
				font-size: 18px;
				text-decoration: none;
				color: $grey-light;
				@include hFontNormal;
				text-transform: none;
				transition: $transition;
				position: relative;
				z-index: 1;
			}

			&:last-child {
				margin-right: 0;
			}
			&:hover, &:active {
				> ul {
					opacity: 1;
					visibility: visible;
					transition-delay: 0s, 0s;
					transform: translate(-50%, 0);
				}
			}
			> ul {
				&:before {
					content: "";
					display: block;
					position: absolute;
					bottom: 100%;
					left: 50%;
					transform: translate(-10px, 3px);
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 10px 10px 10px;
					border-color: transparent transparent #ffffff transparent;
				}

				top: 34px;
				width:200px;
				opacity: 0;
				visibility: hidden;
				z-index: 1;
				transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s,
					transform 0.2s ease 0s;
				transform: translate(-50%, 10px);
				background-color: rgba(255, 255, 255, 1);
				border-radius: $dropRadius;
				@include list();
				position: absolute;
				left: 50%;
				text-align: left;
				box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
				> li {
					&:first-child {
						a {
							border-radius: $dropRadius $dropRadius 0 0;
							padding-top:20px;
						}
					}

					&:last-child {
						a {
							border-radius: 0 0 $dropRadius $dropRadius;
							padding-bottom:20px;
						}
					}					

					a {
						.shrink & {
							padding: 10px 25px;
						}
						padding: 10px 25px;
						color: $pColor;
						transition: $transition;
						display: block;
						@include pFont;
						font-size: 14px;
						text-transform: none;
						&:hover {
							background-color: $secondary;
							color: #fff;
						}
					}
				}
			}

			&.mega-nav {
				> ul {
					width:525px;
					box-sizing:border-box;
					padding:40px;
					display: flex;
					justify-content: space-between;

					&:after {
						content:"";
						display: block;
						width:1px;
						background-color: #979797;
						position: absolute;
						top:40px;
						bottom: 40px;
						left:50%;
						transform:translateX(-50%);
					}

					> li {
						flex:0 0 45%;
						max-width: 45%;
						padding-left:10px;
						box-sizing:border-box;


						a {
							font-size: 14px;
							line-height: 18px;
							padding:4px 0;
							&:hover {
								background-color: transparent;
								border-radius: 0;
								color:$pColor;
								text-decoration: underline;
							}
						}


						> a {
							padding:0;
							margin-bottom: 10px;
							color:$primary;
							text-transform: uppercase;
							font-size: 18px;
							font-weight: bold;

							&:hover {
								color:$primary;
								text-decoration: underline;
							}
							
						}						
					}

					ul {padding:0; margin:0; list-style:none;}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		display: inline-block;
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
