.benefits-features {
	.content {
		background-color: $grey-super-light;
		border-radius: $default-round;
		padding:100px 4vw 4vw;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.17);
		position: relative;

		.item {

		}

		&.benefits {
			margin-bottom: $item-spacing;
		}
	}

	@include media-breakpoint-up(md) {
		.content {
			height:100%;

			&.benefits {
				margin-bottom: 0;
			}
		}	
	}
	@include media-breakpoint-up(lg) {
	
	}
	@include media-breakpoint-up(xl) {
	
	}
}