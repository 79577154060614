footer.content-info {
	background-color: #fff;
	padding: 40px 0;
	border-top:1px solid $grey-light;
	text-align: center;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color:$pColor;
	}

	h6 {
		font-size: 14px;
	}


	.newsletter-wrap {
		margin: 40px auto;
		max-width: 325px;

	}

	.social-footer-wrap,
	.newsletter-wrap {
		h6 {
			margin-top: 0;
		}
	}


	form._form {
		label {
			display: none;
		}

		._checkbox-radio {
			@include clearfix;
			input {
				width:15px;
				display: block;
				float:left;
				position: relative;
				top:2px;
			}
			span {
				display: block;
				float:left;
				width:90%;
				text-align: left;
				padding-left:5px;

			}
			label {
				display: inline-block;
				font-size: 12px;
			}
		}

		._button-wrapper {
			margin-top: 10px;
			padding-bottom: 0;
			text-align: left;

			.btn {
				min-width: 150px;
			}
		}

		._form_element {
			input[type="text"],
			input[type="email"],
			select {
				background-color: $grey-light !important;
				border-color: $grey-light !important;

				&::placeholder {
					color:$off-black !important;
				}

				&:focus {
					background-color: #fff !important;
					border-color: $secondary !important;
				}

				&:hover {
					border-color: $secondary !important;
				}
			}
		}

		ul.gform_fields li.gfield {
			padding-right: 0;
		}
	}

	img {
		max-width: 200px;
	}

	.parent-wrap {
		.img {
			margin-bottom: 10px;
			width: 50px;
			margin: 0 auto;

			img {
				width: 100%;
				display: block;
			}
		}

		p {
			margin: 5px 0 0;
			font-size: 12px;
			color: #e4c9b0;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.newsletter-wrap {
			margin: 0 auto;
			text-align: left;
		}

		.social-footer-wrap {
			text-align: left;
		}

		.gform_wrapper {
			.gform_footer {
				text-align: left;
			}
		}

		.logo-wrap {
			text-align: left;
			max-width: 250px;

			img {
				width: 100%;
			}
		}

		.parent-wrap {
			text-align: right;
			.img {
				display: inline-block;
			}
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		.social-footer-wrap {
			a {
				width:34px;
				height:34px;
				line-height: 40px;
				font-size: 16px;
				margin-left: 8px;
			}
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
