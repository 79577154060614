.services {

	.item {

		margin-bottom: $item-spacing;
	}

	.content-wrap {
		background-color: #fff;
		border-radius: $default-round;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.17);
		height:100%;
		position: relative;
	}

	.title-wrap {
		position: relative;
		text-align: center;
		border-radius: $default-round $default-round 0 0;
		background-color: $primary;
		padding:40px 20px 40px;

		h3 {margin:0; color:#fff;}

		.icon {
			width:55px;
			height:55px;
			position: absolute;
			z-index: 5;
			left:50%;
			bottom:-27px;
			transform:translateX(-50%);

		}

	}

	.content {
		padding:40px 30px 30px;		
		text-align: center;

		.content-title {
			margin-top:0;
			color:$off-black;
		}
	}	


	@include media-breakpoint-up(md) {

		.title-wrap {
			display: flex;
			min-height: 200px;
			align-items:center;

			h3 {
				max-width: 280px;
				margin:0 auto;
			}

		}


	
	}
	@include media-breakpoint-up(lg) {

		.btn-wrap {
			.btn {
				font-size: 14px;
			}
		}		

	
	}
	@include media-breakpoint-up(xl) {

		.btn-wrap {
			.btn {
				font-size: 20px;
			}
		}				
	
	}

}
/* end of .services */