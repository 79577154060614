.wrap {
.block.advanced-callout {
	position: relative;


	&:before, &:after {
		content:"";
		position: absolute;
		left:0; 
		right:0;
		height:20px;
		background-color: #fff;
		z-index: 20;
	}

	&:before {

		bottom:100%;
	}

	&:after {
		display: none;
		top:100%;
	}		


	.item {
		margin-bottom: 30px;
	}
	
	.inner {
		text-decoration: none;
		display: block;
		text-align: center;
		position: relative;
		height: 100%;
		border-radius: $default-round;
		overflow: hidden;

		h3 {margin-top:0;}

		.img-wrap {
			padding-top:60%;
		}


		.description {
			color:$grey-dark;
			line-height: 1.93;
			font-weight: 500;
		}
	}

	

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {



	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}

}