.block.testimonials {
	background-image: url('../images/testimonials.jpg');
	margin:0;


	.testi-wrap {

		margin:0 auto 50px;
		position: relative;
		color:#fff;

		
		blockquote {
			background-color: transparent;
			padding:0;
			margin:0;

			&:before {
				left:-30px;
			}

			&:after {
				right:-30px;
			}
		}
	}

	h5 {color:#fff; margin:0 0 10px;}

	.position {
		font-style: italic;
		color:#fff;
		margin-bottom: 10px;
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {



		.testi-wrap {
			max-width: 740px;
			background-color: #fff;
			border-radius: $default-round;
			position: relative;
			padding:40px 20px;
			color:$pColor;

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translate(-40px, -3px);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 30px 40px 0 40px;
				border-color: #ffffff transparent transparent transparent;
			}

			blockquote {

				margin:20px 40px;
				padding:0 20px;

				&:before {
					left:-20px;
				}

				&:after {
					right:-20px;
				}
			}			


		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}