.menus.mobile {
	position: fixed;
	height: calc(100% - #{$header-mobile-height});
	width: 100%;
	z-index: 100;
	display: block;
	background-color: #fff;
	top: $header-mobile-height; // height of mobile header
	transform:translateX(100%);
	transition: $transition;
	overflow-y: auto;
	padding: 10px 20px 80px;
	.menu-open & {
		transform: translateX(0);
	}

	.admin-bar & {
		top: calc(32px + #{$header-mobile-height});
	}

	.utility-phone {
		text-align: center;
		padding:40px 0 10px;
	}

	.social-wrap {
		padding-top:0px;
		text-align: center;

		a {
			color:$grey-dark;
			font-size: 30px;
			margin-left:20px;

			&:hover {
				color:$primary;
			}
		}
	}

	nav {
		ul {
			@include list();
			li {
				a {
					display: block;
					text-decoration:none;
				}
			}
		}
		&.nav-primary {
			ul {
				li {
					&.menu-item-has-children {
						position: relative;
						&:before {
							content:'\E007';
							display: block;
							position: absolute;
							right: 20px;
							top: 27px;
							transform: translateY(-50%);
							@include iconFont;
							color: $primary;
							font-size: 15px;
							transition: $transition;
							z-index: 5;
						}

						&.open {
							&:before {
								margin-top: -4px;
								transform: translateY(-50%) rotate(-180deg);
							}

							> ul {
								max-height: 500px;
							}
						}
					}
					.sub-menu {
						max-height: 0;
						overflow: hidden;
						transition: $transition;
						li {
							a {
								font-size: 13px;
								padding-left: 30px;
							}
						}

						ul {
							margin-left:25px;

							li {
								&.menu-item-has-children {
									&:before {
										top:24px;
									}
								}
							}

						}	

					}
					a {
						color:$off-black;
						font-size:20px;
						padding:15px 10px;
						border-bottom:1px solid rgba($grey-med, 0.1);
						transition: $transition;
						&:hover {
							color:$primary;
	
						}
					}


				}
			}
		}
		&.nav-utility {
			li {
				&.menu-emergency-services {
					margin: 20px 0;
					a {
						font-size: 17px;
						padding-top: 20px;
						padding-bottom: 20px;
						background-color: $primary;
						color:#ffffff;
						&:hover {
							color:$secondary;
							background-color:rgba(0,0,0,0.1);
						}
					}
				}
				&[class^='utility-'] {
					span {
						display: none;
					}
					a {
						text-decoration: none;
						color:$primary;
						font-size:16px;
						font-weight: 500;
						margin:20px 0;
						&:before {
							@include iconFont;
							color:$primary;
							vertical-align: -2px;
							margin-right: 10px;
						}
					}
				}
				&.utility-email {
					a {
						&:before {
							content: "\E012";
						}
					}
				}
				&.utility-phone {
					a {
						&:before {
							content: "\E011";
						}
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		display: none;
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
	
}
