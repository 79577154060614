.menu-open {
	overflow: hidden;
}

body:not(.wp-admin),
body:not(tinymce) {
	font-family: $pFont;
}

.wrap {
	hr {
		border-color: $secondary;
	}

	padding-top: $header-mobile-height;

	img {
		max-width: 100%;
		height: auto;
	}

	&:before {
		z-index: 100;
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition-property: opacity, visibility;
		transition-duration: 0.2s, 0s;
		transition-delay: 0s, 0.2s;
		background-color: rgba(255, 255, 255, 0.9);
		opacity: 0;
		visibility: hidden;
		.menu-open & {
			opacity: 1;
			visibility: visible;
			transition-delay: 0s, 0s;
		}
	}

	.alert.alert-warning {
		display: block;
		color: #fff;
		background-color: #de8282;
		border: 1px solid #d73030;
		padding: 30px;
		width: 100%;
		margin: 30px 0;
	}

	.img-cover {
		background-position: center center;
		background-size: cover;
	}

	.light-grey-bg, &.light-grey-bg {
		background-color: $grey-light;
	}
	.primary-bg , &.primary-bg {
		background-color: $primary;
	}
	.secondary-bg, &.secondary-bg {
		background-color: $secondary;
	}
	.third-bg, &.third-bg {
		background-color: $third;
	}
	.white-bg, &.white-bg  {
		background-color: #fff;
	}

	.btn-wrap {
		margin: 20px 0;

		&.nmb {
			margin-bottom: 0;
		}

		&.nmt {
			margin-top: 0;
		}

		&.center {
			text-align: center;
		}

		&.right {
			text-align: right;
		}

		&.left {
			text-align: left;
		}
	}

	.uppercase {
		text-transform: uppercase;
	}

	.lowercase {
		text-transform: lowercase;
	}

	.center {
		text-align: center;
	}

	.left {
		text-align: left;
	}

	.right {
		text-align: right;
	}

	.min-height {
		min-height: 300px;
	}

	.max-width,
	.max-width-small {
		margin-left: auto;
		margin-right: auto;
	}

	.max-width {
		max-width: $max-width;
	}

	.max-width-small {
		max-width: 420px;
	}

	.img-wrap {
		img {
			display: block;
			width: 100%;
		}
	}

	.ribbon {
		background-color: $secondary;
		color: #fff;
		@include hFont;
		font-size: 14px;
		display: inline-block;
		height: 36px;
		padding: 0 20px;
		line-height: 36px;
		position: absolute;
		bottom: 20px;
		left: -5px;
		letter-spacing: 1.8px;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.17);

		&.top {
			bottom: auto;
			top: 40px;
		}

		&:after {
			content: "";
			position: absolute;
			left: 100%;
			top: 0;
			background-color: $secondary;
			height: 36px;
			width: 25px;
			z-index: 1;
			transform: skew(-20deg) translateX(-10px);
		}

		&.grey {
			color:$fourth;
			background-color: #D6D6D6;
			box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);

			&:after {
				background-color: #D6D6D6;
			}
		}
	}

	.overlay-20, .overlay-40, .overlay-60, .overlay-80 {
	

		&:before {
			content:"";
			position: absolute;
			z-index: 1;
			top:0;
			bottom: 0;
			right:0;
			left:0;
			display: block !important;


		}

		.container {
			position: relative;
			z-index: 5;
		}
	}

	.overlay-20:before {
		background-color:rgba(black,0.2);
	}

	.overlay-40:before {
		background-color:rgba(black,0.4);
	}

	.overlay-60:before {
		background-color:rgba(black,0.6);
	}

	.overlay-80:before {
		background-color:rgba(black,0.8);
	}




	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.min-height {
			min-height: 400px;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		padding-top: $universal-nav-height;
	}


}
