.block.centered-callouts {
	color:#fff;

	&.no-slider {
		.bg {
			margin:0;
		}
	}


	h4 {margin:0; color:$grey-light;}

	h2 {margin:7px 0 15px; color:#fff;}

	.content {
		padding:12vw 40px;
	}

	.bg {
		background-color: $primary;
		border-radius: $default-round;
		margin:0 40px;
	}

	.slick-slider {
		padding-bottom: 0;
		margin-bottom: 50px;
	}

	.slick-dots {
		bottom: -40px;
	}

	.slick-next {
		right:10px;
	}

	.slick-prev {
		left:10px;
	}



	@include media-breakpoint-up(sm) {

		.content {
			padding:10vw 40px;
		}

		.slick-arrow {
			color:$secondary;

			&:hover {color:$secondary + 20;}
		}
	
	}
	@include media-breakpoint-up(md) {

		// padding-bottom: 70px;

		.slick-dots {
			bottom: -50px;
		}
	
	}
	@include media-breakpoint-up(lg) {
	
	}
	@include media-breakpoint-up(xl) {

		.content {
			padding:8vw 40px;
		}
	
	}

	@media only screen and (min-width: 1300px) {
	    .bg {
			margin:0;
	    }

	}
}