.numeric-pagination {
	text-align: center;
	margin:30px 0;

	> span, > a {
		font-size: 14px;
		display: inline-block;
		margin:0 2px;
		padding:10px 15px;
		border-radius: 6px;

	}

	> a {
		background-color: #fff;
		text-decoration: none;
		color:$off-black;

		&:hover {
			background-color: $secondary;
			color:#fff;

			.icon-triangle-left, .icon-triangle-right {
				&:before, &:after {
					color:#fff;
				}
			}			

		}
	}

	> span {
		background-color: $secondary;
		color:#fff;
	}

	.icon-triangle-left, .icon-triangle-right {
		&:before, &:after {
			position: relative;
			top:-1px;
			color:$secondary;
			font-style: normal;
		}
	}

	.icon-triangle-left {
		&:before {
			content: "\00AB";
			padding-right:7px;
		}
	}

	.icon-triangle-right {
		&:after {
			content: "\00BB";
			padding-left:7px;
		}
	}	


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		// a {
		// 	margin-top: 0;
		// 	&.prev {
		// 		float: left;
		// 	}
		// 	&.next {
		// 		float: right;
		// 	}
		// }

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}

}