.block.video-banner {
	background-size: cover;
	background-position: 50%;
	padding: 10vw 0;

	.container {
		position: relative;
		z-index: 5;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
		background-color: rgba(black, 0.3);
	}

	.icon-wrap {
		width: 80px;
		margin: 0 auto 20px;
	}

	.content {
		padding:0;
		p {
			margin-top: 25px;
		}
	}
}
/* end of .video-banner */
