.single {

	&.single-post {
		.breadcrumb {
			margin-top: 20px;
		}
	}

	.post-header {
		.date {font-size: 14px; @include hFont; margin-bottom: 0;}

		h1 {margin-top:0;}

		.img-wrap {
			margin-bottom: 20px;
		}
	}

	.article-body {
		padding:3vw 0;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.post-header {
			h1 {
				margin-bottom: 0.7em;
			}
			.img-wrap {
				margin-bottom: 35px;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}