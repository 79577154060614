/**
 *	Colours
 */

//ocean-blue
$primary: #0071a3;
//orange
$secondary: #f47721;
//brownish-grey
$third: #626262;
//greyish-brown
$fourth: #484848;

$menu-bg: #f4f4f4;

$off-white: #fefefe;
$off-black: #212121;
$grey-super-light: #f7f7f7;
$grey-light: #e9e9e9;
$grey-med: #9a9a9a;
$grey-dark: #131313;

$pColor: $off-black;
$hColor: $primary;
//default spacing between items when things are stacked eg. blog posts, benefits/features. Also
$item-spacing: 30px;
//global item padding
$item-padding:40px 40px 40px 40px;

$skew: -10deg;

$error: #f93535;

/**
 *	Typography	
 */


$pFont: 'Open Sans', sans-serif;
$hFont: 'Oswald', sans-serif;



/**
 *	Header Heights
 */

$header-desktop-height: 80px;
$header-mobile-height: 100px;
$universal-nav-height: 40px;

$utility-bar-desktop-height: 57px;

/**
 *	Other
 */

$transition: all 0.3s ease;
$max-width: 700px;
$default-round: 24px;