
.btn, .wrap .btn, span.btn {
	display: inline-block;
	position: relative;
	text-decoration: none;
	text-align: center;
	transition: $transition;
	border:none;
	outline:none;
	cursor: pointer;
	font-size: 20px;
	overflow:hidden;
	@include hFontNormal;
	padding: 12px 25px;
	min-width: 170px;
	background-color: $secondary;
	color:#fff;
	border-radius: 12px;
	text-transform: uppercase;
	border:2px solid $secondary;

	&.btn-wysiwyg {
		padding: 0;
		min-width: 0;
		a {
			padding: 12px 25px;
			min-width: 170px;
			color:#fff;
			display: inline-block;
			text-decoration: none;
		}
	}


	&:hover, &:focus {
		background-color: $secondary + 20;
		border-color: $secondary + 20;
		color:#fff;
	}
	span {
		display: block;
		vertical-align: middle;
	}

	&.btn-secondary {
		background-color: $primary;
		border-color: $primary;

		&:hover {
			background-color: transparent;
		}
	}

	&.btn-small {
		font-size: 18px;
		padding:9px 20px;
		min-width: 80px;
	}

	&.btn-square {
		border-radius: 0;
	}
	&.btn-stripped {
		border:none;
		background-color: transparent;
		color:$secondary;
		min-width:100px;
		padding:10px 0;
		text-align: left;
		border-radius: 0;


		&:hover {
			color:$primary;
			background-color: transparent;
		}
	
	}
	&.btn-white {
		background-color: #fff;
		border-color:#fff;
		color:$off-black;

		&.active {
			background-color: $secondary;
			border-color:$secondary;
			color:#fff;

			&:hover {
				background-color: $secondary;
				border-color:$secondary;
				color:#fff;
			}
		}
		&:hover {
			color:#fff;
			background-color: $secondary;
			border-color: $secondary;
		}
	}

	&.btn-outline {
		background-color: transparent;
		color:$fourth;
		&:hover {
			color:#fff;
			background-color: $secondary;
		}
	}	

	&.btn-pdf {
		border:none;
		background-color: transparent;
		color:$off-black;
		min-width:30px;
		padding:10px 0 15px;
		font-size: 14px;
		
		span {
			&:before {
				display: inline-block;
				content:"\E016";
				@include iconFont;
				padding-right:10px;
				position: relative;
				top:5px;
				color:$secondary;
				font-size: 24px;
			}
		}

		&:hover {
			color:$secondary;
			background-color: transparent;
		}	
	}


}

.play-btn {
	display: inline-block;
	position: relative;
	text-decoration: none !important;
	color:#fff !important;

	&:hover {
		opacity: 1;
		color:#fff !important;
		text-decoration: none;

		.icon {
			opacity: 1;
		}
	}	

	.icon {
		display: inline-block;
		width:55px;
		height:55px;
		background-color: #fff;
		opacity: 0.8;
		border-radius: 8px;
		transition:0.3s;
		position: relative;
		
		&:before {
			content:"";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 12.5px 0 12.5px 21.7px;
			border-color: transparent transparent transparent $secondary;
			position: absolute;
			left:50%;
			top:50%;
			transform:translate(-50%,-50%);
			opacity: 1;
			margin-left:1px;

		}		
	}

	.label {
		display: block;
		text-decoration: none;
		margin-top:15px;
		@include hFont;
		font-size: 14px;
		text-align: center;
		color:#fff;
		white-space: nowrap;
		letter-spacing: 1.8px;
		opacity: 1;
		text-decoration: none;
	}


}