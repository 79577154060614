.flexible-features-content {

	.content {
		background-color: #fff;
		border-radius: $default-round;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.17);
		h3 {
			margin-top:0;
			color:$off-black;
		}

		&.benefits {
			margin-bottom: $item-spacing;
		}

		.btn-wrap {
			margin-top:$item-spacing;
			padding:0;
		}
	}

	.btn-pdf {margin-bottom: 10px;}

	@include media-breakpoint-up(sm) {
	
	}
	@include media-breakpoint-up(md) {
		.content {
			height:100%;
			&.benefits {
				margin-bottom: 0;
			}
		}
	}
	@include media-breakpoint-up(lg) {
	
	}
	@include media-breakpoint-up(xl) {
	
	}

}
/* end of .flexible-features-content */