.social-wrap {
	a {display: inline-block; margin-left:10px; font-size: 20px; text-decoration: none; color:#fff; transition:0.3s; vertical-align: middle;

		&:first-child {margin-left:0; }

		&:hover {color:$primary;}

	}

	&.white-round, &.orange-round {
		a {
			border-radius: 50%;
			color: $secondary;
			width: 30px;
			height: 30px;
			line-height: 36px;
			font-size: 14px;
			text-align: center;
			margin-left: 2px;			
		}
	}

	&.white-round {
		a {
			background-color: #fff;
			color:$secondary;

			&:hover {
				background-color: $secondary;
				color: #fff;
			}
		}
	}

	&.orange-round {
		a {
			background-color: $secondary;
			color:#fff;
			
			&:hover {
			background-color: $secondary + 20;
			color:#fff;
			}
		}
	}
}

.share-wrap {
	margin-top:50px;
	p.label {
		margin:0 0 5px;
	}
}