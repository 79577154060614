.site-header {
	position: fixed;
	z-index: 100;
	top: 0;
	width: 100%;

	.admin-bar & {
		top: 32px;
	}

	@media screen and (max-width: 783px) {
		.admin-bar & {
			top: 46px;
		}
	}

	.site-info {
		transition: $transition;
		background-color: #fff;
		.menu-open & {
			background-color: #fff;
		}
		.row.main-header {
			height: $header-mobile-height;
		}

		.brand {
			width: 160px;
			padding-left: 15px;
			img {
				display: block;
				width: 100%;
			}
			&.desktop {
				display: none;
			}
		}

		.customer-service {
			a {
				text-decoration: none;
				color: $primary;
				font-weight: 600;
				text-transform: uppercase;
				font-size: 12px;
				transition: color 0.3s ease;
				&:hover {
					color: $secondary;
					i {
						transform: scale(1.2);
					}
				}
				i {
					transition: transform 0.3s ease;
					display: inline-block;
					font-size: 15px;
					vertical-align: -2px;
					margin-right: 4px;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		position: absolute;
		// .shrink & {
		// 	height: 65px;

		.site-info {
			background-color: transparent;
			padding: 0;

			.single-post &,
			.error404 &,
			.single &,
			.blue-header & {
				background-color: $primary;
			}

			// .shrink & {
			// 	.brand {width:85px;}
			// }

			.row.main-header {
				height: $header-desktop-height;
				transition: $transition;
				.shrink & {
					height: 65px;
				}
			}

			.brand {
				width: 180px;
				&.desktop {
					display: block;
				}
				&.mobile {
					display: none;
				}
			}
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
