.breadcrumb {

	.content {
		border-bottom: 1px solid #d8d8d8;
		padding:14px 0 12px;
		font-size: 12px;
		overflow-x:auto;

		ul, li {padding:0; margin:0; list-style:none;}

		ul {white-space: nowrap;}

		li {display: inline-block;}

		a,span {
			display: inline-block;
			vertical-align: middle;
			margin-right:15px;
			padding-right:25px;
			// margin-bottom: 10px;
		}



		a { 
			position: relative;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}

			&:before {
				content:"\E009";
				@include iconFont;
				position: absolute;
				right:0;
				top:50%;
				transform:translateY(-50%);
				font-size: 10px;
			}

		}
	}

}
/* end of .breadcrumb */