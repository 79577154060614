.accordion {

	padding:6vw 0;

	.item {
		padding-left: 35px;
		margin-bottom:25px;
		.question {
			text-decoration: none;
			color: $pColor;
			font-weight: bold;
			position: relative;
			line-height: 1.5em;
			transition:	$transition;
			&:hover {
				color: $primary + 40;
			}
			&:before {
				display: block;
				width: 24px;
				height:24px;
				line-height: 26px;
				content:'+';
				position: absolute;
				top:50%;
				left:-23px;
				transform:translate(-50%,-50%);
				color: #fff;
				transition: background-color 0.3s;
				background-color:$primary;
				font-weight: normal;
				font-size: 27px;
				text-align: center;
			}
			&.active {
				&:before {
					line-height: 22px;
					content:'-';
					color:$pColor;
					background-color:$grey-light;
				}
			}
		}

		.answer {
			p {
				&:first-of-type {
					margin-top: 5px;
					padding-top: 0;
				}
			}
			transition: $transition;
			max-height: 0;
			overflow:hidden;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding:2vw 0;

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}