.post-item {
	
	
	height:100%;
	padding-bottom: $item-spacing;
	box-sizing:border-box;

	&.minimal {
		height: auto;
		padding-bottom: 10px;
		background-color: #fff;
		border: 1px solid $grey-light;
		border-radius: $default-round;
		padding:40px 30px;
		margin-bottom: $item-spacing;

		.item-wrap {
			box-shadow: none;
			
			height: auto;
			border-radius: 0;
		}

		h3, h4 {margin:0 0 10px; color:$off-black;}

		.btn-wrap {
			margin-top:20px;
		}

		.btn {
			color:$primary;

			&:hover {
				color:$secondary;
			}
		}
	}

	

	.item-wrap {
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.17);
		height:100%;
		border-radius: $default-round;
		background-color: #fff;
		position: relative;
		
	}

	.img-wrap {
		padding-top: 66%;
		position: relative;
		border-radius: $default-round $default-round 0 0;
	}

	.content {
		
	}


	.btn-wrap {
		margin-top: 10px;
	}

	@include media-breakpoint-up(sm) {
	}
	@include media-breakpoint-up(md) {

		&.minimal {
			.title-row {
				h3,h4 {margin:5px 0 0;}
			}

			.item-wrap {
				padding-left:15px;
			}
		}
	}
	@include media-breakpoint-up(lg) {

		.col-lg-4 & {
			.absolute-btn {

				div.content {
					margin-bottom: 45px;
					padding-bottom:75px;
				}					
				

				.btn-wrap {

				}
				
			}
		}
	}
	@include media-breakpoint-up(xl) {
	}
}

/* end of .post-item */
