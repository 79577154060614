.gated-lightbox-outer {
	transition: visibility 0s linear 0.3s, opacity 0.3s linear;

	&.close-lightbox {
		visibility: hidden;
		opacity: 0;
	}


	.gated-lightbox-wrap {
		position: fixed;
		z-index: 2000;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		width:95%;
		max-width: 600px;
	}

	.gated-bg {
		position: fixed;
		top:0;
		left:0;
		right:0;
		bottom: 0;
		background-color: rgba(#000,0.7);
		z-index: 1800;
	}


	.gated-sign-up {
		box-shadow: 0 0 30px rgba(black,0.3);
		position: relative;
		margin:0 auto;
		width:100%;
		background-color: $grey-light;
		border-radius: $default-round;

		.content {
			max-width: 450px;
			margin:0 auto;
		}

				.form-wrap {
			text-align: left;
		}


		._form-label {
			display: none;
		}


		h3 {color:$off-black; font-size:20px; margin-bottom: 10px;}

		p {font-size: 14px; line-height: 20px;}

		ul.gfield_checkbox, ul.gfield_radio {
			li {
				label {
					line-height: 14px;
					font-size: 12px;
				}
			}
		}

		.close {
			position: absolute;
			top:5px;
			right:5px;
			font-size: 38px;
			color:$off-black;
			z-index: 10;

			&:hover {
				cursor: pointer;
				color:$secondary;
			}



		}

		.download-wrap {
			&.hide {
				display: none;
			}
		}

		.gform_confirmation_message {
			padding:15px;
			background-color: #fff;
			border-radius: 14px;
			border:1px solid $secondary;
			margin:20px 0 !important;
			@include hFont;
			text-transform: none;

		}	

	}	




		@include media-breakpoint-up(sm) {

		}
		@include media-breakpoint-up(md) {

			.gated-sign-up {

				h3 {font-size:32px; }

				p {font-size: 16px; line-height: 26px;}
			}


		}
		@include media-breakpoint-up(lg) {



		}
		@include media-breakpoint-up(xl) {

		}
	
}
/* end of .gated-lightbox-outer  */

