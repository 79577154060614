.category-list {
	@include list();
	li {
		display: inline-block;
		margin-right: 10px;
		font-family: $hFont;
		font-size: 12px;
		letter-spacing: 1.3px;
		.wrap .main & {
			margin-bottom:10px;
		}
		&:after {
			content:'|';
			margin-left: 10px;
		}
		&:last-child {
			margin-right: 0;
			&:after {
				display: none;
			}
		}
	}
}