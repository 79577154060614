.image-callout {
	position: relative;

	.img-wrap {
		max-width: 400px;
		margin:0 auto 30px;
	}

	.content {
		padding:0 15px;
	}

	.description {
		*:first-child {
			margin-top: 0;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		.img-wrap {
			height:100%;
			max-width: none;
			margin:0;

			// img {
			// 	display: inline-block;
			// 	width:99%;
			// 	vertical-align: middle;
			// }
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
