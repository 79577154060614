  .slick-dots {
    position: absolute;
    bottom: 0px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    left: 0;
    right: 0;
    li {
      position: relative;
      display: inline-block;
      height: 12px;
      width: 12px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      button {
        border-radius: 100%;
        border: 0;
        background: $grey-light;
        display: block;
        height: 12px;
        width: 12px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer;
        transition: 0.3s;
        &:hover,
        &:focus {
          background: $secondary;
          outline: none;
          &:before {
            opacity: 1;
          }
        }
      }

      &.slick-active {
        button {
          background: $secondary;
        }
      }
    }

    @include media-breakpoint-up(md) {


        li {
          height: 19px;
          width: 19px;
          margin: 0 14px;
          button {
            height: 19px;
            width: 19px;
          }

          .slick-small-dots & {
              margin:0 12px;
              height:15px;
              width:15px;

              button {
                height:15px;
                width:15px;
              }
          }

        }
    }
}

  .slick-arrow {
    position: absolute;
    top: 50%;
    border: none;
    transform: translateY(-50%);
    background: none;
    color: $grey-light;
    font-size: 0;
    opacity: 1;
    transition: 0.3s;
    z-index: 20;
    width: 45px;
    padding: 0;
    margin: 0;

    &.slick-disabled {
      opacity: 0.4;

      &:hover {
        opacity: 0.4;
      }
    }

    &:hover {
      color: $secondary;
      cursor: pointer;
    }

    &:active,
    &:focus {
      outline: none;
      border: none;
    }

    &:before {
      @include iconFont;
      font-size: 50px;
      text-align: center;
    }

    &.slick-prev {
      left: 0px;

      &:before {
        content: "\E008";
      }
    }
    &.slick-next {
      right: 0px;

      &:before {
        content: "\E009";
      }
    }

    @include media-breakpoint-up(md) {

        &.slick-prev {
          left: 15px;
        }
        &.slick-next {
          right: 15px;
        }

    }
}


.slick-slider {
  padding-bottom: 40px;

  &.fma-slider {
    padding-bottom: 0;

    .slick-dots {

      li button {
        background-color: #fff;
      }

      li.slick-active button {
        background-color: $secondary;
      }
    }
  }
  &.slick-initialized.vertically-center {
    .slick-slide {
      float: none;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.wave-slider {
    .content {
      padding-bottom: 50px;
    }

    .slick-dots {
      bottom: 15%;
      z-index:3;
    }
  }

  &.nav-slider {
    .content {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .slick-slide {
    &:focus,
    &:active {
      outline: none;
      border: none;
    }

    .content {
      // transition: 0.4s;
      // transition-delay: 0.3s;
      // opacity: 0;
      // transform:translate(50px,0);
      // margin-top: 40px;
    }
    &.slick-active {
      .content {
        // opacity: 1;
        // margin-top: 0;
        // transform:translate(0,0);
      }
    }    
  }

  &.slick-dotted {
    // padding-bottom: 40px;

    .slick-arrow {
      margin-top: -25px;
    }
  }

  &.slick-nav {
    padding: 0 60px;
  }




  @include media-breakpoint-up(md) {
    &.nav-slider {
      .content {
        // padding-left: 60px;
        // padding-right: 60px;
      }
    }

  }

  /* =============================================================================
    Desktop
    ========================================================================== */
  @include media-breakpoint-up(lg) {
    &.slick-nav {
      padding: 0 80px;
    }
  }

  @media only screen and (min-width: 1300px) {
    &.nav-slider {
      .content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
} /* end of .slick-slider  */
