.socket {
	text-align: center;
	font-size: 10px;
	background-color:$grey-light;
	padding:15px;
	color: $pColor;
	.copyright {
		line-height: 1.8em;
		padding-bottom: 15px;
		span {
			display: inline-block;
		}
	}
	.nav {
		@include list();
		display: inline-block;
		margin-left: 10px;
		li {
			display: inline-block;
			margin-right: 5px;
			margin-bottom: 0;
			&:last-child {
				margin-right: 0;
				a {
					&:after {
						margin-left: 0px;
						content:'';
					}
				}
			}
			a {
				color: $pColor;
				text-decoration: none;
				transition:$transition;
				&:hover {
					color: $secondary;
					&:after {
						color:$pColor;
					}
				}
				&:after {
					color:$pColor;
					margin-left: 10px;
					content:'|';
				}
			}
		}
	}
	.attribution {
		margin-top: 15px;
		display: block;
		color: $pColor;
		text-decoration: none;

		&:hover {
			color:$secondary;
		}
		span, i {
			vertical-align: middle;
			display: inline-block;
		}
		i {
			vertical-align: -2px;
			margin-left: 2px;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.copyright {
			text-align: left;
			padding-bottom: 0;
		}

		.attribution {
			margin-top: 0;
			text-align: right;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}
