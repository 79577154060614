.block {
	position: relative;

	&.img-cover {
		margin:0;
	}

	.intro-container {
		text-align: center;
		margin-bottom: 50px;

		h4 {margin-top:0; margin-bottom: 5px; color:$off-black;}

		h2 {
			margin-top: 0;
			margin-bottom: 0.5em;
		}

		&.light {
			color:#fff;
			h2,h4 {color:#fff;}
		}

		&.small-padding {
			margin-bottom: 10px;
		}
	}

	.btn-wrap {
		margin-top:$item-spacing;
		margin-bottom: 0;
		

		.btn-stripped {
			padding:0;
		}

		&.has-multiple {
			.btn {margin-bottom: 5px;}
			.btn:first-child {
				margin-right: 10px;
			}
		}
	}

	//add wrapper class to content area to create absolute position buttons
	.content {
		position: relative;
		padding:$item-padding;

		&.max-width {
			max-width: 400px;
			margin-left:auto;
			margin-right:auto;
		}

		*:first-child {
			margin-top:0;
		}

		*:last-child {
			margin-bottom: 0;
		}
	}

	.absolute-btn {
		.btn-wrap {
			margin-top:-10px;
			padding:0 40px 40px;
			text-align: center;
		}

		&.absolute-btn-left {
			.btn-wrap {
				text-align: left;
			}
		}
	}


	.view-all-link {
		text-align: center;
		margin-top: $item-spacing;

		&.no-margin {
			margin-top:0;
		}
	}

	&.light-grey-bg {
		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background-color: $grey-light;
			z-index: 20;
		}

		&:before {
			bottom: 100%;
		}

		&:after {
			top: 100%;
		}
	}

	.gform_wrapper {
		margin-bottom: 0;
	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.content {

			&.large-padding {
				padding:70px 100px;
			}

		}
		//add wrapper class to content area to create absolute position buttons
		.col-md-4,.col-md-6, .col-md-3 {


			&.absolute-btn, .absolute-btn {


				.content {
					margin-bottom: 85px;

					&.large-padding {
						margin-bottom: 110px;

						.btn-wrap {
							bottom: 70px;
						}
					}
				}				
				
				.btn-wrap {
					text-align: center;
					margin:0;
					position: absolute;
					bottom:40px;
					left:40px;
					right:40px;
					padding:0;
				}
				
			}

			&.absolute-btn-left, .absolute-btn-left {
				.btn-wrap {
					text-align: left;
				}

				.large-padding {
					.btn-wrap {
						left:100px;
						right:100px;
					}
					
				}
			}

			&.absolute-btn-multiple, .absolute-btn-multiple{
				.content {
					margin-bottom: 110px;
				}	
			}				

		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		.col-lg-4,.col-lg-6, .col-lg-3 {
				
			&.absolute-btn, .absolute-btn {

				.content {
					margin-bottom: 85px;

					&.large-padding {
						margin-bottom: 110px;

						.btn-wrap {
							bottom: 70px;
						}
					}					
				}					
				

				.btn-wrap {
					margin:0;
					position: absolute;
					bottom:40px;
					left:40px;
					right:40px;
					padding:0;
					text-align: center;

				}
				
			}

			&.absolute-btn-left, .absolute-btn-left {
				.btn-wrap {
					text-align: left;
				}

				.large-padding {
					.btn-wrap {
						left:100px;
						right:100px;
					}
					
				}
			}	

			&.absolute-btn-multiple, .absolute-btn-multiple{
				.content {
					margin-bottom: 160px;
				}	
			}				
		}		
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
