

.block .home-banner,
.block .interior-banner {
	height: 500px;
	position: relative;
	overflow: hidden;
	position: relative;
	z-index: 1;
	background-color: $primary;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-color: rgba(black, 0.2);
		z-index: 3;
	}

	.img-cover {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	.img-cover {
		z-index: 5;
		// mix-blend-mode: multiply;
	}

	.container {
		z-index: 10;
		position: relative;
	}

	.container,
	.row,
	.col-sm-12 {
		height: 100%;
	}

	.content {
		position: absolute;
		top: 50%;
		left: 15px;
		transform: translateY(-50%);
		color: #fff;
		max-width: 480px;
		padding:0;

		h1 {
			color: #fff;
			margin: 0;
		}
		h3 {
			margin: 15px 0 0;
		}

		p {
			margin: 15px 0 0;
		}
		.btn.btn-stripped {
			padding-left: 0;
			margin-top: 20px;

			&:hover {
				color: #fff;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
		height: 400px;
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		height: 600px;
		.content {
			h1 {
				font-size: 44px;
			}
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		height: 700px;
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		height: 850px;
		.content {
			h1 {
				font-size: 52px;
			}
		}
	}
}


.interior-banner {
	padding: 12vw 0;
	height: auto;
	.content {
		text-align: center;
		margin: 0 auto 20px;
	}

	@include media-breakpoint-up(md) {
		padding: 6vw 0;
	}
}
