.home-banner,
.wave-wrap {
	position: relative;
	overflow:hidden;
	.archive &, .blog &, .light-grey-bg & {
		.wave-svg {
			path {
				fill: $grey-light;
			}
		}
	}

	.svg-wave-container {
		overflow: hidden;
		width:100%;
		z-index: 5;
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		z-index: 2;
		height: 7.5vw;
		background-image:url('../images/wave-white.svg');
		background-position:center;
		background-size:cover;
		background-repeat:no-repeat;
		.wave-svg {
			width:101%;
		}
	}

	&.light-grey-wrap {
		.svg-wave-container {
			background-image:url('../images/wave-grey.svg');
		}
	}
}


.light-grey-bg {
		.svg-wave-container {
			background-image:url('../images/wave-grey.svg');
		}
	}